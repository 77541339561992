import { Link } from 'gatsby'
import React from 'react'
import "./Button.scss"

const Button = ({ children, to, btnType, iconLeft, icon, style, ...other }) => {

  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <button
        style={style}
        {...other}
      >
        <Link
          to={to}
          className={"btn btn-"+btnType + " " + (iconLeft ? "btn-icon-left" : "")}
        >
              <span class="body-input">
                  {children}
              </span>
              {icon}
        </Link>
      </button>
      
    )
  }
  return (
  <button
    style={style}
    {...other}
  >
    <a
      href={to}
      rel="noreferrer"
      target="_blank"
      className={"btn btn-"+btnType + " " + (iconLeft ? "btn-icon-left" : "")}
    >
          <span class="body-input">
              {children}
          </span>
          {icon}
    </a>
  </button>
  )
}

Button.defaultProps={
    btnType:"primary",
    iconLeft: false,
    icon: ""
}

export default Button