import React from "react"
import "./layout.css"
import Footer from "./Footer/Footer.js"
import Navbar from "./Navbar/Navbar.js"

export default function Layout({ children }) {
  return( 
    <>
    <Navbar />
    <div className="body-container">
      <div className="grid-container">
        {children}
      </div>
      <Footer />
    </div>
    </>
  )
}