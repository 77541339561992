import React from 'react'
import { Link } from 'gatsby'
import logo from '/src/logo.svg'
import { Cross as Hamburger } from 'hamburger-react'
import "./Navbar.scss"
import { FaArrowRight } from 'react-icons/fa';
import Button from '../Button/Button'

/**
* Navigation component
*
* The Navigation component takes an array of your Ghost
* navigation property that is fetched from the settings.
* It differentiates between absolute (external) and relative link (internal).
* You can pass it a custom class for your own styles, but it will always fallback
* to a `site-nav-item` class.
*
*/
class Navbar extends React.Component {

    constructor(props) {
        super(props);
        this.expandNav = this.expandNav.bind(this);
        this.state = {
        expanded: false,
        };
    }

    expandNav = () => {
        this.setState(state => ({
            expanded: !state.expanded,
        }));
    }

    render(){
        return(
            <>
                <div className={"navbar " + (this.state.expanded ? "navbar-active" : "")}>
                    <Link to="/" className="nav-group nav-group-left">
                        <img src={logo} alt="Logo" />
                        <div className="h4">ampmusic.co</div>
                    </Link>
                    <div className={"nav-group nav-group-right " + (this.state.expanded ? "nav-group-right-active" : "")}>
                        <a className="body-input" href="https://pulse.ampmusic.co" target="_blank" rel="noreferrer">blog</a>
                        <Link className="body-input" to="/about">about</Link>
                        <Button
                            icon={<FaArrowRight/>}
                            btnType="secondary"
                            to='/waitlist'
                        >
                            join waitlist
                        </Button>
                    </div>
                    <div className="hamburger-container">
                        <Hamburger 
                            size={18} 
                            rounded
                            onToggle={this.expandNav}
                        />
                    </div>
                </div>
            </>
        )
    };
}


export default Navbar