import "./Card.scss"

/** @jsx jsx */
import { jsx, css } from '@emotion/react'
export default function Card(props) {

    var alignItems = props.alignItems;

    return( 
        <div 
            className={"card"}
            css={css`
                align-items: ${alignItems};
            `}
        >
            {props.children}
        </div>
    )

}

Card.defaultProps={
    alignItems : "center"
}