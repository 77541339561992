import "./GridSpan.scss"

/** @jsx jsx */
import { jsx, css } from '@emotion/react'

export default function GridSpan(props) {

    var span_mobile = props.span[0];
    var span_tablet = props.span[1];
    var span_desktop = props.span[2];
    var span_desktopBig = props.span[3];

    var justifyItems = props.justifyItems;

    var order = props.order;

    return( 
        <div 
            className={"span"}
            css={css`
            justify-items: ${justifyItems};

            @media (max-width: 600px) {
                grid-column: span ${span_mobile};
            }
            @media (min-width: 601px) and (max-width: 900px) {
                grid-column: span ${span_tablet};
            }
            @media (min-width: 901px) and (max-width: 1200px) {
                grid-column: span ${span_desktop};
            }
            @media (min-width: 1201px) {
                grid-column: span ${span_desktopBig};
            }
            @media (max-width: 600px){
                order: ${order};
            }
            `}
        >
            {props.children}
        </div>
    )

}

GridSpan.defaultProps = {
    span : [12,12,12,12],
    justifyItems : "stretch",
    order: "0"
}