import React from "react"
import "./Footer.scss"
import { Link } from "gatsby"
import { FaTwitter, FaFacebook, FaInstagram, FaCopyright, FaEnvelope } from 'react-icons/fa';

export default function Footer(props) {

    return( 
        <div className="footer">
            <div className="footer-col footer-col-left">
                <div>
                    <a 
                        href="https://pulse.ampmusic.co"
                        className="body-input"
                        target="_blank"
                        rel="noopener noreferrer" 

                        style={{
                            color: "var(--neutral-02)"
                        }}
                    >blog</a>
                    <Link 
                        to="/about"
                        className="body-input"
                        style={{
                            color: "var(--neutral-02)"
                        }}
                    >about</Link>
                </div>
                <div>
                    <Link
                        to="/terms-of-service"
                        className="body-input"
                        style={{
                            color: "var(--neutral-03)"
                        }}
                    >terms and conditions</Link>
                    <Link
                        to="/privacy"
                        className="body-input"
                        style={{
                            color: "var(--neutral-03)"
                        }}
                    >privacy policy</Link>
                </div>
            </div>
            <div className="footer-col footer-col-right">
                <div className="icon-row">
                    <a 
                        rel="noopener noreferrer" 
                        href="https://www.instagram.com/ampmusic.co/" target="_blank"
                    >
                        <FaInstagram/>
                    </a>
                    <a 
                        rel="noopener noreferrer" 
                        href="https://www.facebook.com/ampmusic.co" target="_blank"
                    >
                        <FaFacebook/>
                    </a>
                    <a 
                        rel="noopener noreferrer" 
                        href="https://twitter.com/ampmusic_co" target="_blank"
                    >
                        <FaTwitter/>
                    </a>
                    <a 
                        href="mailto:hello@ampmusic.co"
                    >
                        <FaEnvelope/>
                    </a>
                </div>
                <div
                    className="copyright"
                >
                        <FaCopyright/>
                        <div
                            className="body-input"
                        >{new Date().getFullYear()} ampmusic.co</div>
                </div>
            </div>
        </div>
    )

}